::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, gray);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(transparent, #df0e62);
}

button {
  border-radius: 30px;
  cursor: pointer;
  font-family: "Oswald", arial, sans-serif;
  outline: none;
}

select {
  -moz-appearance:none;
  -ms-appearance:none;
  -webkit-appearance:none;
  appearance:none;
  background-image: none;
  background: #df0e62;
  border:0!important;
  box-shadow:none;
  color:#fff;
  cursor:pointer;
  flex: 1;
  font-family: "Oswald", arial, sans-serif;
  font-size: 1em;
  outline:0;
  padding: 0 .5em;
}
select::-ms-expand {
  display: none;
}
.select {
  background: #df0e62;
  border-radius: 30px;
  display: flex;
  line-height: 2;
  overflow: hidden;
  position: relative;
}

.select::after {
  background: #000;
  content: '\25BC';
  cursor: pointer;
  padding: 0 1em;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition:.25s all ease;
}
.select:hover::after {
  color: #df0e62;
}

@media screen and (max-width: 768px) {
  .selectResponsive {
    margin-left: 170px;
  }
  }

@media screen and (min-width: 769px) {
  .selectResponsive {
    margin-left: 550px;
  }
}

.questionUser {
  color: black;
  height: 20px;
  margin-bottom: -10px;
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .questionUser {
    font-size: 0.8rem;
  }
  }

@media screen and (min-width: 769px) {
  .questionUser {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  select option {
    text-align: center;
  }
  }

.navButton {
  background: none;
  border: none;
  color: #df0e62;
  cursor: pointer;
  display: block;
  outline: none;
  text-align: left;
}

.navButton:hover {
  color: #970942;
  outline: none;
  transition: .3s all ease;
}

.likesDiv {
  display: flex;
  justify-content: center;
}

.likesButtons {
  margin-left: 10px;
}

.nickNameInput {
  padding-left: 10px;
  padding-right: 10px;
}

.nickNameInput::placeholder {
  color: white;
  text-decoration: none;
}

.button {
  animation: button-slide-up 0.5s ease-in-out forwards;
  opacity: 0;
  position: relative;
}
  
@keyframes button-slide-up {
  from {
    bottom: -50px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}

.dataPanelDiv {
  margin-top: 50px;
}

@media screen and (min-width: 769px) {
  .dataPanelDiv {
    margin-left: 40%;
  }
}

@media screen and (max-width: 768px) {
  .dataPanelDiv{
    margin-left: 10%;
  }
  }