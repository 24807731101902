.loadingMiniContainer {
    align-items: center;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: 500px;
}

.loaderMini {
    align-items: center;
    animation: rotation 0.5s linear infinite;
    background: rgb(2, 0, 36);
    background: linear-gradient(
    43deg,
    rgb(207, 51, 129) 0%,
    rgb(206, 23, 124) 35%,
    rgba(0, 212, 255, 1) 100%
    );
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    height: 50px;
    justify-content: center;
    padding: 10px;
    width: 50px;
}

.loaderMini div {
    background-color: white;
    border-radius: 50%;
    box-sizing: border-box;
    height: 20px;
    width: 20px;
}

@keyframes rotation {
    from {
    transform: rotate(0deg);
    }
    to {
    transform: rotate(358deg);
    }
}