.modal {
    --transform: translateY(-100vh);
    --transition: transform .8s;
    background-color: #111111bd;
    bottom: 0;
    display: flex;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity .6s .9s;
    z-index: 1 !important;
}

.modal.is-open {
    --transform: translateY(0);
    --transition: transform .8s .8s;
    opacity: 1;
    pointer-events: unset;
    transition: opacity .6s;
}

.modal-container {
    /* max-width: 450px; */
    /* padding: 3em 2.5em; */
    background: #1a1a1a;
    border-radius: 20px;
    border: 2px solid gray;
    color: #fff;
    display: grid;
    gap: 1em;
    grid-auto-columns: 100%;
    height: auto;
    margin: auto;
    max-height: 500px;
    max-width: 350px;
    padding: 1em 1em;
    place-items: center;
    text-align: center;
    transform: var(--transform);
    transition: var(--transition);
    width: auto;
}

.modalConfirm {
    background-color: rgb(31, 202, 31);
    border-radius: 6px;
    border: 1px solid;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: 300;
    padding: 1em 3em;
    text-decoration: none;
    transition: background-color .3s;
    width: 150px;
} 

.modalClose {
    background-color: rgb(224, 11, 11);
    border-radius: 6px;
    border: 1px solid;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: 300;
    padding: 1em 3em;
    text-decoration: none;
    transition: background-color .3s;
    width: 150px;
} 

.modalTitle {
    margin-top: -20px;
}